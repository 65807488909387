.gform_fields {
  @extend .list-unstyled;
  @extend .row;

  input, select, textarea {
    @extend .form-control;
  }

  textarea {
    height: auto;
  }

}

.gfield_description {
  //@extend .alert;
}

.validation_error {
  //@extend .alert;
  //@extend .alert-danger;
}

.validation_message {
  //@extend .alert;
  //@extend .alert-success;
}
input.button,
.gform_button {
  @extend .btn;
  @extend .btn-primary;
  @extend .btn-lg;
  @extend .m-t;

}

.gfield_required {
  color: $alert-danger-text;
}

.gform_wrapper ul.gfield_radio li, .gform_wrapper ul.gfield_checkbox li {
  @extend .checkbox-inline;
}

.gform_validation_container {
  display: none;
}

.gfield_error .ginput_container {
  margin-bottom: 0px;
}
.gform_wrapper ul.gfield_checkbox li input[type=checkbox], .gform_wrapper ul.gfield_radio li input[type=radio] {
  /* width: auto !important; */

  padding: 20px;
  margin: 20px;
  margin-right:2px;

  display: block;
  float: left;
}

.checkbox-inline input[type=checkbox], .checkbox input[type=checkbox], .radio-inline input[type=radio], .radio input[type=radio] {
  position: relative;
  margin-left:0px;
  margin-top: 14px;
  margin-right:10px;
}
.gform_wrapper ul.gfield_checkbox li, .gform_wrapper ul.gfield_radio li {
  padding: 0 !important;
  margin-right:8px;
}

.gform_wrapper.gf_browser_chrome .gfield_checkbox li input[type=checkbox], .gform_wrapper.gf_browser_chrome .gfield_radio li input[type=radio], .gform_wrapper.gf_browser_chrome .gfield_checkbox li input {
  margin-top: 8px;
}

 .gform_wrapper ul li.field_description_below div.ginput_container_radio, body .gform_wrapper ul li.field_description_below div.ginput_container_checkbox {
  margin-top: 0px;
  margin-bottom: 0;
}


body .gform_wrapper li.gfield.gfield_error,
body .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
  background-color: transparent;
  margin-bottom: 0px !important;
  border-top: 0px solid #C89797;
  border-bottom: 0px solid #C89797;
  padding-bottom: 0px;
  padding-top: 0px;
  box-sizing: border-box;
}

body .gform_wrapper .field_description_below .gfield_description {
  padding-top: 0px;
}
@media only screen and (min-width: 641px) {
.gform_wrapper .top_label li.gfield.gfield_error.gf_left_half {
  margin-right: 0px;
}
}

body .gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_description {
  max-width: 100%;
  padding-right: 0px;
}

.comment-form p, .form-group {
  margin-bottom: 0px;
}

.checkbox-inline input[type=checkbox], .checkbox input[type=checkbox], .gform_wrapper ul.gfield_checkbox li input[type=checkbox], .gform_wrapper ul.gfield_radio li input[type=checkbox], .radio-inline input[type=radio], .radio input[type=radio] {

  height:20px;
}

body .gform_wrapper .top_label li.gfield.gfield_error.gf_left_half {
  margin-right: 8px;
}

body .gform_wrapper input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) {
  padding: 8px 12px;
}

//body .gform_wrapper .gf_page_steps {
//  border-bottom: 1px solid #ddd;
//  margin:0px;
//  padding:0px;
//
//}
//body .gform_wrapper .gf_step {
//  margin-right: 2px;
//  line-height: 1.42857143;
//  border: 1px solid transparent;
//  border-radius: 4px 4px 0 0;
//
//  width: auto;
//
//  margin: 0;
//
//  position: relative;
//  display: inline-block;
//  padding: 20px 15px;
//}
//
//body .gform_wrapper .gf_step span {
//  font-size: $font-size-base;
//}
//body .gform_wrapper .gf_step_active{
//  color: #555;
//  cursor: default;
//  background-color: #fff;
//  border: 1px solid #ddd;
//  border-bottom-color: transparent;
//
//  padding: 20px 15px;
//  display: inline-table;
//}

