.btn-default:hover,
.btn-default:active,
.btn-default:focus {
  background:#396c77;
  color:#fff;
}

.btn-embossed {
  box-shadow: inset 0 -2px 0 rgba(0,0,0,.15);
}

.btn-cta {
  @extend .btn-primary;
  background: #E4981B;
  border-color: #BC7809;

}

.btn-cta {
  @extend .btn-primary:hover;
  background: #BC7809;
  border-color: #E4981B;

}

.tooltip-arrow,
.bg-tooltip + .tooltip > .tooltip-inner {background-color: #396c77; }