footer .app-footer {
  //background-color:#d8e8e8;
  //background:#d8e8e8;




}
footer ul > li > a {
  color:rgba(255,255,255,.8);
}

footer .app-footer h6 {
  color:#fff;
}

footer {
  background:url('/app/themes/huijs/dist/images/footer-bg.jpg') #fff -220px 100%;
  background-repeat:no-repeat;
  min-height: 300px;

  img {
    -webkit-border-radius:12px;
    -moz-border-radius:12px;
    border-radius: 12px;
  }
}
@media screen and (max-width: $screen-lg-min) {
  footer {
    background: #f0f5f8;
  }
}

.footer-bottom {
  margin-top: 10px;

  @media screen and (max-width: $screen-lg-min) {

    border-top:1px solid #fff;


  }
}

.footer-bottom .nav>li>a {
  color:#7d7d7d;
  margin-left:0;
  padding-left:0;
}

.footer-bottom .navbar-nav>li>a {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
}

.footer-bottom .nav>li>a:focus,
.footer-bottom .nav>li>a:hover,
.footer-bottom .nav>li.current-menu-item a{
  color:#417c88;


  background:none;
  background-color:none;

  -webkit-border-radius:0px;
  -moz-border-radius:0px;
  border-radius:0px;
}