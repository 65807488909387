// CTA blocks homepage
// three blocks on grey background

  .hp-block {

    text-align: left;

    background: #FFFFFF;
    border: 1px solid #EAE9E9;
    border-radius: $border-radius-large;

    .btn {
      display: block;
      width: 100%;
      border-radius: $border-radius-base;
    }

    h2 {
      color: #56C008;
      font-size: 2.4rem;
      font-weight: 300;

    }
  }



@media (min-width: $screen-md-min) {
  .hp-block {
    text-align: center;

    .btn {
      display: block;
      width: auto;
    }

    h2 {
      color:#56C008;
      font-weight: 300;
    }
  }
}

// Background image block
// Block with background image with white text

.bg-block {

  min-height: 260px;

  h1 {
    color: #FFFFFF;
    line-height: 34px;
    text-shadow: 0px 2px 4px rgba(0,0,0,0.50);
  }

  p.lead,
  .lead {

    font-weight: 300;
    color: #FFFFFF;
    text-shadow: 0px 2px 4px rgba(0,0,0,0.50);

  }
}




// block-wc-categories

.wc-cat-block .btn-default,
.wc-cat-block .btn-default:active {
  border:2px solid #fff;
  color:#fff;
  font-weight:400;
  bottom:0;
}

.wc-cat-block .btn-default:hover,
.wc-cat-block .btn-default:active,
.wc-cat-block .btn-default:focus {
  background:#88358D;
  color:#fff;
}

.wc-cat-block {

  z-index:1;
  min-height: 340px;

  h2,
  .btn {
    z-index:10;
  }

  .layer {
    width: 100%;
    min-height: 338px;
    display: block;
    position: absolute;
    z-index:-10;
    background-color:rgba(0,0,0,0.40);
    -webkit-border-radius:24px;
    -moz-border-radius:24px;
    border-radius:24px;
  }

}

.subscribe-newsletter {
  background:rgba(87,22,90,.6);
}

.subscribe-newsletter h2,
.subscribe-newsletter p.lead {
  color:#fff;
}


// Session sign up container

.block-session {
  background-color:$brand-primary;

  -webkit-border-radius:$border-radius-large;
  -moz-border-radius:$border-radius-large;
  border-radius:$border-radius-large;

  display:block;

  h3 {
    color:#fff;
  }

  p {
    color:#fff;
  }

  span.date {
    font-weight: 200;
    color:#fff;
  }

 }

@media (max-width: $screen-sm-max) {
  .block-session {
    text-align: center;
  }
  .block-session .btn {
    margin-top:10px;
  }
}


.block-usps {
  background-color:#fff;
  border:1px solid #EAE9E9;

  -webkit-border-radius:$border-radius-large;
  -moz-border-radius:$border-radius-large;
  border-radius:$border-radius-large;

  position: relative;

  span.icon {
    color:$brand-green;
  }

  img {
    right:24px;
    bottom:0px;
    overflow: hidden;
  }
}

.block-workshop-subscribe {

  .alert-subscribe {
    background-color:$brand-primary;

    -webkit-border-radius:$border-radius-large;
    -moz-border-radius:$border-radius-large;
    border-radius:$border-radius-large;
  }

  h2,
  p.lead,
  p {
    color:#fff;
  }
}