.page-header {
  padding-bottom: 0px;
  margin: 0;
  border-bottom: none;
}

.header-bg {
  background: url("../images/background.jpg");

  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;

  min-height:320px;
}

@media screen and (max-width: $screen-lg-min) {
  .header-bg {
    background-position: left center;
  }
}

@media screen and (max-width: $screen-md-min) {
  .header-bg {
    background-position: left center;
    min-height:240px;
  }
}


@media screen and (max-width: $screen-sm-min) {
  .header-bg {
    background-position: left top;

    min-height:200px;
  }
}