.support {

  right:10px;
  bottom:10px;
  position: fixed!important;
  z-index:999;


}

.support .support-circle {
  border:4px solid #7B1F81;
  background:#fff;
  z-index:888;
  position: relative;

  width: 120px;
  height: 120px;

  -webkit-border-radius:100px;
  -moz-border-radius:100px;
  border-radius:100px;

}

.support .support-circle img {
  z-index: 900;
  position: absolute;
  bottom: 0px;
  right: 0px;
}


.bubble a {
  position:absolute;

  left: -150px;
  top: 20px;
  z-index: 9999;

  width: 140px;
  height: 32px;
  padding: 2px;
  background: #56C008;

  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;

  color:#fff;
  font-weight:400;

}

.bubble a:hover,
.bubble a:active {
  color:#fff;
}

.bubble a:after
{
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 10px 0 10px 17px;
  border-color: transparent #56C008;
  display: block;
  width: 0;
  z-index: 1;
  right: -12px;
  top: 6px;
}