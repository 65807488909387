.navbar {
  //background-color:#fff;
  //background:#fff;
}

  .breadcrumb {
    background:transparent;
    margin-bottom:0;
  }

  .contact-bar{
    //margin-top:140px;
    //background-color: #DAE9E9;
    -webkit-border-radius:12px;
    -moz-border-radius:12px;
    border-radius: 12px;
    border:1px solid #DAE9E9;


    //background:url("../images/header-aanmelden.jpg") #fff left top no-repeat;


    img {

      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      -webkit-border-top-left-radius: 12px;
      -webkit-border-top-right-radius: 12px;
      -moz-border-radius-topleft: 12px;
      -moz-border-radius-topright: 12px;
      max-width:100%;
      max-height:100%;
    }

    @media screen and (max-width: $screen-sm-max) {
      img {
        border-left-top-radius: 12px;
        border-top-right-radius: 0px;
        border-left-bottom-radius:12px;

        -webkit-border-top-left-radius: 12px;
        -webkit-border-top-right-radius: 0px;
        -webkit-border-bottom-left-radius: 12px;
        -moz-border-radius-topleft: 12px;
        -moz-border-radius-bottomleft: 12px;
        -moz-border-radius-topright: 0px;

       min-height:207px;
      }
    }

    @media screen and (max-width: $screen-xs-max) {
      img {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border-bottom-left-radius: 0px;
        -webkit-border-top-left-radius: 12px;
        -webkit-border-bottom-left-radius: 0px;
        -webkit-border-top-right-radius: 12px;
        -moz-border-radius-topleft: 12px;
        -moz-border-radius-topright: 12px;
        min-height:207px;
      }
    }
    .btn {
      margin-top:-5px;
    }

    .tooltip {
      width:80%;
    }

    .wait-time {
      margin-top:5px;
    }

  }

.navbar-nav>li {
  margin-left:2px;
}

.nav>li>a:focus,
.nav>li>a:hover,
.nav>li.current-menu-item>a {
  color:#fff;

  -webkit-border-radius:12px;
  -moz-border-radius:12px;
  border-radius:12px;

  background:#417c88;


}
.nav>li>a:focus,
.nav>li>a:hover {
  background:rgba(65,124,136,.4);
}





.navbar-toggle {
  position: relative;
  float: right;
  margin-right: 15px;
  padding: 9px 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: #7fb2b2;
  color:#fff;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 12px;

  span.icon-bar {
    color:#fff;
    background-color:#fff;
    background:#fff;
  }

  .navbar-toggle:active {
    background:#417c88;
    background-color:#417c88;

  }
}

.navbar button:hover,
.navbar button:active {

  background:#417c88;
  background-color:#417c88;

}

.nav-border {
  min-height:10px;
  background:#fff;
  background-color:#fff;
  border-top:1px solid #7fb2b2;
}

.top-header {
  background:#325b67;
  background-color:#325b67;

  .navbar-nav>li>a {
    padding-top: 6px;
    padding-bottom: 6px;

    color:#90b2ba;
    border-radius: 0;

    font-size:13px;
  }

}

.navigation {

  @media screen and (max-width: $screen-sm-max) {
  .navbar-nav>li>a {
    padding: 8px;

    font-size:14px;
  }
  }


}